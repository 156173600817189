<template>
  <div>
    <v-toolbar color="accent" dark dense>
      <v-toolbar-title
        >Quote Details - {{ quote.project_name }}</v-toolbar-title
      >
    </v-toolbar>
    <v-row dense>
      <v-col cols="12" class="d-flex flex-column justify-end">
        <v-row dense class="mt-5">
          <v-col cols="12">
            <span>
              From
              {{
                `${quote.user_details.username} - ${quote.user_details.email}`
              }}
              at
              {{
                quote.company_details.name
                  ? quote.company_details.name
                  : quote.user_details.company_request
              }}</span
            >
          </v-col>
        </v-row>
        <v-row dense class="my-5">
          <v-col cols="12">
            <span>
              <v-chip outlined label color="primary" class="mr-2">{{
                quote.number_of_fixtures
              }}</v-chip>
              x
              <v-chip outlined label color="primary" class="mx-2">{{
                `${quote.project_type_name}'s`
              }}</v-chip>
              in
              <v-chip outlined label color="primary" class="mx-2">{{
                quote.project_timeline_name
              }}</v-chip></span
            >
          </v-col>
        </v-row>
        <v-row dense class="mt-5 my-sm-5">
          <v-col cols="12">
            <span>
              <v-textarea
                dense
                label="Time Line Details"
                rows="4"
                readonly
                auto-grow
                outlined
                flat
                hide-details
                :value="
                  quote.project_timeline_details
                    ? quote.project_timeline_details
                    : 'Not indicated'
                "
            /></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "QuoteSetupDiv",
  props: {
    quote: {
      type: Object,
      default: null,
    },
  },
};
</script>
